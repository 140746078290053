import { Label, Image } from "@shared/UI";
const FooterItems = (props) => {
  return (
    <div className="w-full sm:w-1/3 pl-4 sm:pl-0 flex flex-row justify-start sm:justify-center items-center">
      <div className="mr-2">
        <Image default={props.image} className="w-6 sm:w-6 lg:w-8 xl:w-11" />
      </div>
      <div>
        <Label
          color="text-white"
          className="text-4xl sm:text-3xl lg:text-4xl xl:text-5xl"
        >
          {props.children}
        </Label>
      </div>
    </div>
  );
};

export default FooterItems;
