import Image from "@shared/UI/Image";
import ImageBox2 from "@assets/images/upup/upup-box-2.png";
import BackgroundSection2 from "@assets/images/upup/bg-section-2.jpg";
import ImageMan from "@assets/images/upup/man.png";
import ImageMen from "@assets/images/upup/men.png";
import ImageBox3 from "@assets/images/upup/upup-box-3.png";
import ImageProofIcon from "@assets/images/upup/oy.png";
import TopicHeader from "@components/UpupComponents/UpupTopicHeader/UpupTopicHeader";
import { Label } from "@shared/UI";
import TextLists from "@components/UpupComponents/TextLists/TextLists";
import styles from "./styles.module.scss";

const Section2 = () => {
  return (
    <div
      dir="section2"
      className="bg-auto bg-no-repeat"
      style={{ backgroundImage: "url(" + BackgroundSection2 + ")" }}
    >
      <div
        dir="subSection"
        className="w-full relative bg-black bg-opacity-90 py-4 sm:py-0"
      >
        <div dir="article" className="w-full sm:w-4/5 mx-auto px-4 sm:p-0">
          <Label
            as="span"
            className="text-4xl sm:text-5xl md:text-6xl lg:text-7.5xl font-bold leading-none px-4 my-2 bg-red-600 absolute sm:-top-7 md:-top-12"
          >
            ดีอย่างไร?
          </Label>
          <TextLists size="lg" className="pt-12 md:pt-16 md:pb-10 z-20">
            <li>
              <span className="font-bold text-2xl sm:text-4xl md:text-6xl lg:text-relative-6/5">
                ชัดทุกสัมผัส ชัดทุกความรู้สึก
              </span>
            </li>
            <li>
              กระปรี้กระเปร่า{" "}
              <span className="font-bold text-2xl sm:text-4xl md:text-6xl lg:text-relative-6/5">
                พร้อมฟาด
              </span>{" "}
              <br /> ทุกสถานการณ์
            </li>
            <li>เติมพลังความฟิต</li>
            <li>เพิ่มความมั่นใจให้คุณเต็ม 100</li>
            <li>ระบบเผาผลาญร่างกายดีขึ้น</li>
          </TextLists>
        </div>
        <div
          dir="man"
          className="absolute right-8 -bottom-64 z-10 hidden lg:block"
        >
          <Image default={ImageMan} />
        </div>
        {/* article */}
      </div>
      {/* SubSection */}
      <div
        dir="footer"
        className={`relative flex flex-wrap sm:flex-nowrap justify-between items-end pb-14 z-20 ${styles.overlay}`}
      >
        <div className="pl-4 sm:pl-24 w-full flex- sm:w-auto sm:flex-initial order-2 sm:order-1">
          <Label
            as="div"
            className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold leading-none"
          >
            1 กล่อง : 4 แคปซูล
          </Label>
          <Label
            as="div"
            className="text-md sm:text-xl lg:text-2xl leading-none"
          >
            อ.ย. 30-1-13758-5-0012
          </Label>
        </div>
        <div className="pr-10 w-full w-full flex-auto sm:w-auto sm:flex-initial order-1 sm:order-2">
          <Image default={ImageBox2} />
        </div>
      </div>
      {/* footer */}
      <div dir="subSection2">
        <div
          dir="H-Topic"
          className="flex items-center my-4 sm:my-11 pl-2 sm:pl-4 md:pl-8 lg:pl-12"
        >
          <TopicHeader size="text-3xl sm:text-5xl md:text-5xl lg:text-7.5xl">
            ส่วนผสมจากสารสกัดธรรมชาติ
          </TopicHeader>
        </div>
        <div className="flex flex-wrap px-4 md:px-10 lg:px-28 flex-1">
          <div dir="left" className="w-full sm:w-1/2">
            <ul className="shadow-md opacity-90 bg-redBlood p-1 w-full">
              <li className="flex justify-between items-center px-2 sm:px-5 py-3  text-xl md:text-2xl lg:text-4xl">
                <div>สารสกัดจากโสม</div>
                <div>100 มก.</div>
              </li>
              <li className="flex justify-between items-center px-5 py-3  text-xl md:text-2xl lg:text-4xl bg-black">
                <div>สารสกัดจากเนื้อหอยนางรม</div>
                <div>100 มก.</div>
              </li>
              <li className="flex justify-between items-center px-5 py-3  text-xl md:text-2xl lg:text-4xl">
                <div>สารสกัดจากเห็ดหลินจือ</div>
                <div>50 มก.</div>
              </li>
              <li className="flex justify-between items-center px-5 py-3  text-xl md:text-2xl lg:text-4xl bg-black">
                <div>สารสกัดจากกระชายดำ</div>
                <div>50 มก.</div>
              </li>
              <li className="flex justify-between items-center px-5 py-3  text-xl md:text-2xl lg:text-4xl">
                <div>โปรตีนถั่วเหลืองไอโซเลต</div>
                <div>50 มก.</div>
              </li>
              <li className="flex justify-between items-center px-5 py-3  text-xl md:text-2xl lg:text-4xl bg-black">
                <div>แอลคาร์นิทีน</div>
                <div>50 มก.</div>
              </li>
              <li className="flex justify-between items-center px-5 py-3  text-xl md:text-2xl lg:text-4xl">
                <div>กรดแอลฟ่า-ไลโพอิก</div>
                <div>40 มก.</div>
              </li>
              <li className="flex justify-between items-center px-5 py-3  text-xl md:text-2xl lg:text-4xl bg-black">
                <div>โคเอนไซน์ คิวเท็น</div>
                <div>30 มก.</div>
              </li>
              <li className="flex justify-between items-center px-5 py-3  text-xl md:text-2xl lg:text-4xl">
                <div>ซิงค์ อะมิโน แอซิด คีเลต 21%</div>
                <div>30 มก.</div>
              </li>
              <li className="flex justify-between items-center px-5 py-3  text-xl md:text-2xl lg:text-4xl bg-black">
                <div>วิตามินอี</div>
                <div>&nbsp;</div>
              </li>
            </ul>
          </div>
          <div
            dir="right"
            className="flex justify-center items-center w-full sm:w-1/2"
          >
            <Image default={ImageBox3} />
          </div>
        </div>
      </div>
      {/* Subsection2 */}
      <div
        dir="subsection3"
        className={`pt-4 sm:pt-10 md:pt-14 lg:pt-20 relative ${styles.overlayAbsolute}`}
      >
        <div className="sm:mx-4 md:mx-10 lg:mx-24">
          <Label
            as="span"
            className="text-4xl sm:text-5xl md:text-6xl lg:text-7.5xl font-bold leading-none px-4 my-2 bg-red-600"
          >
            วิธีรับประทาน
          </Label>
        </div>
        <div className="mt-2 md:mt-5 lg:mt-8 sm:mx-4 md:mx-10 lg:mx-24 z-20 relative">
          <article className="p-4 sm:p-6 bg-black bg-opacity-75">
            <h3 className="text-3xl sm:text-4xl md:text-6xl lg:text-7xl">
              ทาน 1 แคปซูล
            </h3>
            <TextLists size="md" color="red">
              <li>
                <span className="font-bold text-2xl sm:text-4xl md:text-5xl lg:text-relative-6/5">
                  ตอนเช้าหรือก่อนนอน
                </span>{" "}
                จะช่วยดูแลองค์รวมของร่างกายดีขึ้น
              </li>
              <li>
                <span className="font-bold text-2xl sm:text-4xl md:text-5xl lg:text-relative-6/5">
                  ก่อนทำภารกิจ 30 นาที{" "}
                </span>
                จะช่วยเพิ่มความฟิตให้กับร่างกาย กระปรี้กระเปร่า ลดอาการหลั่งเร็ว
                มีความสุขได้ยาวนานมากขึ้น
              </li>
            </TextLists>
          </article>
          <div
            dir="men"
            className="absolute -right-24 -bottom-52 hidden lg:block"
            style={{ zIndex: "-100" }}
          >
            <Image default={ImageMen} />
          </div>
        </div>
        <div
          dir="proof"
          className={`w-full text-center relative sm:pt-4 md:pt-10 lg:pt-20 z-50`}
        >
          <div dir="H-Topic" className="flex items-center sm:pl-4 md:pl-12">
            <TopicHeader
              size="text-xl sm:text-3xl md:text-4xl lg:text-6xl"
              className="sm:py-2 md:py-4"
            >
              <div className="flex items-center">
                <div className="w-8 sm:w-12">
                  <Image default={ImageProofIcon} />
                </div>
                <div className="pl-3 z-10">
                  เลขที่จดแจ้ง องค์การอาหารและยา(อย.)
                </div>
              </div>
            </TopicHeader>
          </div>
        </div>
      </div>
      {/* Subsection3 */}
    </div>
  );
};

export default Section2;
