import { useState, useCallback } from "react";
import Section1 from "@components/UpupComponents/Section1/Section1";
import Section2 from "@components/UpupComponents/Section2/Section2";
import Section3 from "@components/UpupComponents/Section3/Section3";
import Section4 from "@components/UpupComponents/Section4/Section4";
import Section5 from "@components/UpupComponents/Section5/Section5";
import Section6 from "@components/UpupComponents/Section6/Section6";
import Section7 from "@components/UpupComponents/Section7/Section7";
import Section8 from "@components/UpupComponents/Section8/Section8";
import Footer from "@components/UpupComponents/Footer/Footer";
import AbsoluteFooter from "@components/UpupComponents/AbsoluteFooter/AbsoluteFooter";
import { UpupContext } from "@shared/context/upupContext";

const UpupContainer = () => {
  const [isSubmitDone, setSubmittingDone] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [signalScrollToPurchase, setSignalScrollToPurchase] = useState(false);

  const signalScrollPurchaseHandler = useCallback((value) => {
    setSignalScrollToPurchase(value);
  }, []);

  const setSubmitHandler = useCallback((value) => {
    setSubmittingDone(value);
  }, []);

  const setSubmittingHandler = useCallback((value) => {
    setSubmitting(value);
  }, []);

  return (
    <UpupContext.Provider
      value={{
        signalScrollToPurchase: signalScrollToPurchase,
        isSubmitDone: isSubmitDone,
        isSubmiting: isSubmitting,
        setSubmitDone: setSubmitHandler,
        setSignalScroolPurchase: signalScrollPurchaseHandler,
        setSubmiting: setSubmittingHandler,
      }}
    >
      <div className="container mx-auto max-w-upupScale pb-12 lg:pb-20">
        <main>
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
          <Section5 />
          <Section6 />
          <Section7 />
          <Section8 />
        </main>
        <footer>
          <Footer />
        </footer>
        <AbsoluteFooter />
      </div>
    </UpupContext.Provider>
  );
};

export default UpupContainer;
