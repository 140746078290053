import { Field } from "formik";
import styles from "../styles.module.scss";
import Label from "@shared/UI/Label";

const SelectTypeForm = (props) => {
  return (
    <div className={`group ${styles.customRadio}`}>
      <Field
        type="radio"
        id={props.id}
        name={props.name}
        value={props.value}
        className="hidden"
      />
      <Label
        as="label"
        htmlFor={props.id}
        color="text-black"
        className="text-lg sm:text-2xl lg:text-4xl xl:text-6xl font-bold cursor-pointer group-hover:text-red-800 "
      >
        <span className="w-4 h-4 sm:w-5 sm:h-5 lg:w-7 lg:h-7 inline-block mr-2 sm:mr-4 rounded-full border-4 border-red-800 flex-no-shrink"></span>
        {props.children}
      </Label>
    </div>
  );
};

export default SelectTypeForm;
