import { Field } from "formik";
import { useRef } from "react";
import Label from "@shared/UI/Label";

const UploadFileForm = (props) => {
  const inputFileRef = useRef();
  const triggerFile = () => {
    inputFileRef.current.click();
  };
  return (
    <>
      <div
        dir="preview"
        className={`h-80 mb-4 ${props.previewImage || "hidden"}`}
      >
        <img
          src={props.previewImage}
          className="max-h-full h-full"
          alt="Mediashoping"
        />
      </div>
      <Field
        type="file"
        name="image"
        className="hidden"
        accept=".jpg,.png,.jpeg"
        onChange={props.selectImageFile}
        validate={props.validateImage} /* สร้าง Validator เอง */
        innerRef={inputFileRef}
      />
      <button
        type="button"
        className="outline-none bg-green-600 text-white px-4 py-2 rounded-md text-xl sm:text-2xl
      hover:bg-green-400 focus:outline-none "
        onClick={triggerFile}
      >
        เลือกไฟล์
      </button>

      {props.errorSelectFile && (
        <Label color="text-red-600" as="div" className="text-xl sm:text-2xl">
          {props.errorSelectFile}
        </Label>
      )}
    </>
  );
};

export default UploadFileForm;
