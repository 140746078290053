import Image from "@shared/UI/Image";

const AbsoluteFooterItems = (props) => {
  const jsx = props.link ? (
    <a
      className={`w-1/3 flex flex-row justify-center items-center rounded-md m-0.5 sm:m-1 cursor-pointer ${props.className}`}
      href={props.link}
      rel="noreferrer"
      target={props.target || "_self"}
    >
      <div className="mr-2">
        <Image default={props.image} className="w-6 sm:w-8 lg:w-11" />
      </div>
      <div>{props.children}</div>
    </a>
  ) : (
    <div
      className={`w-1/3 flex flex-row justify-center items-center rounded-md m-0.5 sm:m-1 lg:m-2 cursor-pointer ${props.className}`}
      onClick={props.signalScrollHandler}
    >
      <div className="mr-2">
        <Image default={props.image} className="w-6 sm:w-8 lg:w-11" />
      </div>
      <div>{props.children}</div>
    </div>
  );

  return jsx;
};

export default AbsoluteFooterItems;
