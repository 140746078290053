import { BrowserRouter, Route } from "react-router-dom";
import routes from "@root/routes";

const App = () => {
  return (
    <BrowserRouter>
      {routes.map(({ path, component }) => (
        <Route key={path} exact path={path} component={component}></Route>
      ))}
    </BrowserRouter>
  );
};

export default App;
