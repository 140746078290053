import { useState } from "react";
import Countdown from "react-countdown";

const CountdownComponent = (props) => {
  const [resetCountDown, setResetCountdown] = useState(
    Math.random().toString(36).substring(7)
  );
  const complete = () =>
    setResetCountdown(Math.random().toString(36).substring(7));

  const renderer = ({ minutes, seconds }) => {
    // Render a countdown
    return (
      <div className={props.className}>
        <div>
          {minutes}:{seconds.toString().padStart(2, 0)}
        </div>
      </div>
    );
  };
  return (
    <Countdown
      key={resetCountDown}
      {...props}
      renderer={renderer}
      onComplete={complete}
    />
  );
};

export default CountdownComponent;
