import Image from "@shared/UI/Image";
import BackgroundSection5 from "@assets/images/upup/bg-section-5.jpg";
import Imagebox5 from "@assets/images/upup/upup-box-5.png";
import ImageboxCombo from "@assets/images/upup/upup-box-combo.png";
import styles from "./styles.module.scss";
import { Label } from "@shared/UI";

const Section5 = () => {
  return (
    <div
      dir="section5"
      className="bg-auto bg-no-repeat py-6 sm:py-10"
      style={{ backgroundImage: "url(" + BackgroundSection5 + ")" }}
    >
      <div className="text-center mb-4 lg:mb-0">
        <Label
          as="span"
          className={`text-2xl sm:text-4xl md:text-6xl lg:text-8xl font-bold leading-none px-7 py-1 bg-red-600 ${styles.textShadow}`}
        >
          โปรโมชั่น
        </Label>
      </div>
      <div className="flex flex-row flex-wrap">
        <div className="w-1/2 flex flex-wrap flex-row justify-center items-center">
          <div className="flex-initial w-full text-center items-center">
            <Image default={Imagebox5} />
            <div
              className={`flex-initial w-full text-center ${styles.textShadow}`}
            >
              <Label className="text-2xl sm:text-4xl md:text-6xl lg:text-7xl font-bold">
                <span className="text-relative-5/4 text-yellow">1</span> กล่อง{" "}
                <span className="text-relative-5/4 text-yellow">299</span> บาท
              </Label>
            </div>
          </div>
        </div>
        <div className="w-1/2 text-left ">
          <div className="flex-initial w-full text-left">
            <Image default={ImageboxCombo} />
          </div>
          <div
            className={`flex-initial w-full text-center ${styles.textShadow}`}
          >
            <Label className="text-2xl sm:text-4xl md:text-6xl lg:text-7xl font-bold">
              <span className="text-relative-5/4 text-yellow">4</span> กล่อง{" "}
              <span className="text-relative-5/4 text-yellow">900</span> บาท
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
