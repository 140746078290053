import image1 from "@assets/images/upup/footer-article-1.png";
import image2 from "@assets/images/upup/footer-article-2.png";
import image3 from "@assets/images/upup/footer-article-3.png";
import FigtureItems from "./FigtureItems/FigtureItems";
const Section8 = () => {
  return (
    <div dir="section8" className="bg-white">
      <div className="flex flex-row flex-wrap px-12 py-4 justify-center w-full pb-24">
        <FigtureItems image={image1} />
        <FigtureItems image={image2} />
        <FigtureItems image={image3} />
      </div>
    </div>
  );
};

export default Section8;
