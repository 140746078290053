import { useState } from "react";
import { ErrorMessage } from "formik";
import Label from "@shared/UI/Label";
import PaymentChannelItems from "./PaymentChannelItems";
import Image from "@shared/UI/Image";
import accountImage from "@assets/images/upup/account.jpg";
import UploadFileForm from "../UploadFileForm/UploadFileForm";

const PaymentChannel = (props) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [errorSelectFile, setErrorSelectFile] = useState(null);

  const previewImages = (file) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewImage(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  };

  const selectImageFile = (event) => {
    let imageData = null;
    if (event.target.files) {
      if (event.target.files[0].size > 10485760) {
        alert("ขนาดไฟล์ไม่เกิน 10MB");
        return false;
      }
    }
    if (event.target.files && event.target.files.length === 1) {
      imageData = event.target.files[0];
      previewImages(imageData);
      props.setImageFile(imageData);
    }
    return true;
  };

  const validateImage = () => {
    let error = null;
    if (props.formikProps.values.paymentType === "TRANSFER") {
      if (!props.imageFile) {
        error = "กรุณาแนบไฟล์หลักฐานการโอนเงิน";
      }
    }
    setErrorSelectFile(error);
    return error;
  };

  return (
    <div className="mb-10">
      <div>
        <Label color="text-black" className="text-2xl xl:text-4xl">
          ช่องทางการชำระเงิน
        </Label>
      </div>
      <div
        role="group"
        aria-labelledby="my-radio-group"
        className={`flex flex-row flex-wrap items-center`}
      >
        <PaymentChannelItems
          id="paymentType1"
          value="DEST"
          activeValue={props.formikProps.values.paymentType}
        >
          ชำระเงินปลายทาง
        </PaymentChannelItems>
        <PaymentChannelItems
          id="paymentType2"
          value="TRANSFER"
          activeValue={props.formikProps.values.paymentType}
        >
          โอนเงิน
        </PaymentChannelItems>
      </div>
      <div className="text-red-600 text-2xl">
        <ErrorMessage name="paymentType" />
      </div>
      <div
        dir="transferDetail"
        className={`px-4 sm:px-10 py-4 my-4 w-full bg-gray-100 ${
          props.formikProps.values.paymentType !== "TRANSFER" && "hidden"
        }`}
      >
        <article>
          <Label
            as="div"
            color="text-black"
            className="text-2xl sm:text-4xl mb-2"
          >
            1. โอนเงินเข้ามาที่บัญชี{" "}
            <span className="text-red-800 font-bold text-relative-5/4">
              บจ. มีเดีย ช็อปปิ้ง
            </span>
            <div className="text-red-700 font-bold">
              * หลังจากนั้นเก็บหลักฐานการโอนเงินไว้ เพื่อใช้ในการแจ้งชำระเงิน
            </div>
          </Label>
          <div className="lg:w-2/5 mb-4">
            <Image default={accountImage} />
          </div>
          <Label
            as="div"
            color="text-black"
            className="text-2xl sm:text-4xl mb-2"
          >
            2. แนบหลักฐานการโอนเงิน
          </Label>
          <UploadFileForm
            validateImage={validateImage}
            selectImageFile={selectImageFile}
            previewImage={previewImage}
            errorSelectFile={errorSelectFile}
          />
        </article>
      </div>
    </div>
  );
};

export default PaymentChannel;
