import styles from "./styles.module.scss";
import Label from "@shared/UI/Label";

const UpupTopicHeader = (props) => {
  return (
    <div
      className={`
      xl:px-12 xl:py-2 ${styles.parent} ${props.className || ""}`}
    >
      <Label
        as={props.as || "h1"}
        className={` font-bold leading-none ${props.size}`}
      >
        {props.children}
      </Label>
    </div>
  );
};

export default UpupTopicHeader;
