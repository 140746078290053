import { useContext } from "react";
import AbsoluteFooterItems from "./AbsoluteFooterItems/AbsoluteFooterItems";
import Label from "@shared/UI/Label";
import lineIcon from "@assets/images/upup/line-icon-footer.png";
import callIcon from "@assets/images/upup/call-icon-footer.png";
import cartIcon from "@assets/images/upup/cart-icon-footer.png";
import { UpupContext } from "@shared/context/upupContext";

const AbsoluteFooter = (props) => {
  const context = useContext(UpupContext);

  const scrolltoPurchaseSignal = () => {
    if (!context.isSubmiting) {
      context.setSignalScroolPurchase(true);
      if (context.isSubmitDone) {
        context.setSubmitDone(false);
      }
    }
  };

  return (
    <div className="fixed w-full bottom-0 left-0 z-50">
      <div className="container mx-auto max-w-upupScale bg-gray-200 shadow-md p-0.5 sm:p-1 flex flex-row flex-nowrap rounded-md">
        <AbsoluteFooterItems
          className="bg-lineBg hover:bg-green-400 block"
          image={lineIcon}
          signalScrollHandler={null}
          link="https://line.me/R/ti/p/@mediashopping"
          target="_blank"
        >
          <Label
            color="text-white"
            className="hidden sm:block md:text-2xl lg:text-4xl"
          >
            สอบถาม
          </Label>
        </AbsoluteFooterItems>
        <AbsoluteFooterItems
          className="bg-red-700 hover:bg-red-400 block"
          image={callIcon}
          signalScrollHandler={null}
          link="tel:+6621131314"
        >
          <Label
            color="text-white"
            className="hidden sm:block text-2xl lg:text-4xl"
          >
            โทร
          </Label>
        </AbsoluteFooterItems>
        <AbsoluteFooterItems
          signalScrollHandler={scrolltoPurchaseSignal}
          className="bg-blue-600 hover:bg-blue-400"
          image={cartIcon}
        >
          <button
            type="button"
            className="block focus:outline-none outline-none"
          >
            <Label
              color="text-white"
              className="hidden sm:block text-2xl lg:text-4xl"
            >
              สั่งซื้อ
            </Label>
          </button>
        </AbsoluteFooterItems>
      </div>
    </div>
  );
};
export default AbsoluteFooter;
