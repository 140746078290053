import { webpSupport } from "@utils/dom";

const Image = (props) => {
  const source = props.webp
    ? webpSupport()
      ? props.webp
      : props.default
    : props.default;

  return (
    <img
      src={source}
      alt={props.alt || "Mediashopping"}
      className={`max-w-full ${props.display || "inline-block"} ${
        props.className || ""
      }`}
    />
  );
};

export default Image;
