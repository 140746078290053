import { Field, ErrorMessage } from "formik";
import Label from "@shared/UI/Label";

const InputForm = (props) => {
  return (
    <div className="mb-4">
      <div>
        <Label color="text-black" className="text-xl sm:text-2xl xl:text-4xl">
          {props.label}
        </Label>
      </div>
      <div>
        <Field
          as={props.as || "input"}
          name={props.name}
          type={props.type || "text"}
          placeholder={props.placeholder}
          rows={props.rows || 5}
          className="p-4 text-xl sm:text-2xl xl:text-3xl bg-gray-100 w-full text-gray-600 outline-none"
        />
      </div>
      <div className="text-red-600 text-xl sm:text-2xl">
        <ErrorMessage name={props.name} />
      </div>
    </div>
  );
};

export default InputForm;
