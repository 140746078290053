import Image from "@shared/UI/Image";
import Logo from "@assets/images/upup/logo.png";
import ImageBox1 from "@assets/images/upup/upup-box-1.png";
import BackgroundSection1 from "@assets/images/upup/bg-section-1-3.jpg";
import TopicHeader from "@components/UpupComponents/UpupTopicHeader/UpupTopicHeader";
import { Label } from "@shared/UI";

const Section1 = () => {
  return (
    <div
      dir="section1"
      className="bg-auto bg-no-repeat 
      pb-10
      sm:pb-16
      md:pb-20
      xl:pb-40"
      style={{ backgroundImage: "url(" + BackgroundSection1 + ")" }}
    >
      <div dir="LogoBrand" className="p-3">
        <Image
          default={Logo}
          className="
        w-36
        sm:w-1/4 "
        />
      </div>
      {/* Contents Section1 */}
      <div
        dir="H-Topic"
        className="flex justify-center items-center 
      my-4
      sm:my-6
      xl:my-11"
      >
        <TopicHeader
          size="
        text-2xl
        sm:text-4xl
        md:text-5xl
        xl:text-7.5xl "
        >
          อัพอัพ ผลิตภัณฑ์เสริมอาหารผู้ชาย
        </TopicHeader>
      </div>
      <div
        className="w-3/6 mx-auto text-left
      py-2
      xl:py-4"
      >
        <Label
          className="font-bold leading-none
        text-2xl
        sm:text-4xl
        md:text-5xl
        xl:text-7.5xl"
        >
          บำรุงสุขภาพ
        </Label>
        <Label
          as="span"
          className="font-bold leading-none my-2 bg-red-600 inline-block
          text-2xl px-2
          sm:text-4xl sm:px-4
          md:text-5xl
          xl:text-7.5xl"
        >
          ปลุกพลังความฟิต
        </Label>
        <Label
          className="leading-none
        text-xl
        sm:text-4xl
        md:text-5xl
        xl:text-7xl"
        >
          เคล็ดลับที่ผู้ชายต้องลอง!
        </Label>
        <Image
          default={ImageBox1}
          className="
        w-28
        sm:w-full
        "
        />
      </div>
      <div className="flex justify-between">
        <div className="px-4">
          <Label
            as="span"
            className="font-bold leading-none 
          text-md
          sm:text-xl
          xl:text-4xl"
          >
            1 กล่อง : 4 แคปซูล
          </Label>
        </div>
        <div className="px-4">
          <Label
            as="span"
            className="leading-none
           text-md
          sm:text-xl
          xl:text-2xl"
          >
            อ.ย. 30-1-13758-5-0012
          </Label>
        </div>
      </div>
      <div
        className="w-11/12 mx-auto border-8 border-red-700 
      my-7
      sm:my-16"
      >
        <div className="aspect-w-16 aspect-h-9">
          <iframe
            src="https://www.youtube.com/embed/MHe9c1q9yp8"
            title="YouTube video player"
            allow="autoplay;"
          ></iframe>
        </div>
      </div>

      {/* End Contents Section1 */}
    </div>
  );
};

export default Section1;
