import Image from "@shared/UI/Image";
import styles from "./styles.module.scss";
import BackgroundSection4 from "@assets/images/upup/bg-section-4.jpg";
import Imagebox4 from "@assets/images/upup/upup-box-4.png";
import TopicHeader from "@components/UpupComponents/UpupTopicHeader/UpupTopicHeader";
import { Label } from "@shared/UI";
import { personReviews } from "./personReviews";
import { lineReviews } from "./lineReviews";

const Section4 = () => {
  return (
    <div
      dir="section4"
      className="bg-auto bg-no-repeat pt-4 sm:pt-14"
      style={{ backgroundImage: "url(" + BackgroundSection4 + ")" }}
    >
      <div className="text-center">
        <Label
          as="span"
          className="text-2xl sm:text-5xl md:text-6xl lg:text-7.5xl font-bold leading-none px-6 py-2 bg-red-600"
        >
          รีวิวจริงจากลูกค้า
        </Label>
      </div>
      <div className="flex flex-row flex-wrap justify-center mt-6 sm:mt-14">
        {personReviews.map((image) => {
          return (
            <div
              key={image.default}
              className="max-w-1/4 m-1 border-2 border-yellow-300"
            >
              <Image default={image.default} />
            </div>
          );
        })}
      </div>
      <div className="mt-10 sm:mt-20 pl-4 sm:pl-6">
        <TopicHeader
          as="span"
          size="text-3xl sm:text-5xl md:text-6xl lg:text-8xl"
          className="inline-block"
        >
          รีวิวจริงจากลูกค้า
        </TopicHeader>
      </div>
      <div className="relative mt-10">
        <div className="m-1 text-center">
          <Image
            default={lineReviews[0].default}
            className="border-2 border-yellow-300"
          />
        </div>
        <div className="m-1 text-center">
          <Image
            default={lineReviews[1].default}
            className="border-2 border-yellow-300"
          />
        </div>
        <div className="m-1 text-center">
          <Image
            default={lineReviews[2].default}
            className="border-2 border-yellow-300"
          />
        </div>
        <div className="m-1 text-center">
          <Image
            default={lineReviews[3].default}
            className="border-2 border-yellow-300"
          />
        </div>
        <div className="m-1 text-center">
          <Image
            default={lineReviews[4].default}
            className="border-2 border-yellow-300"
          />
        </div>
        <div className="absolute right-10 sm:-bottom-20 md:-bottom-14 lg:-bottom-56 xl:-bottom-12 transform rotate-0 xl:-rotate-3">
          <Label className="text-xl md:text-3xl">อ.ย. 30-1-13758-5-0012</Label>
        </div>
      </div>
      <div className="pl-3 sm:pl-7 pb-4 flex flex-row flex-wrap items-end ">
        <Image default={Imagebox4} />
        <div className="pl-14">
          <Label
            className={`text-4xl sm:text-5xl md:text-6xl lg:text-9xl font-bold ${styles.textStroke}`}
            color="text-black"
          >
            อึด ทน ฟิตเต็มร้อย
          </Label>
        </div>
      </div>
    </div>
  );
};

export default Section4;
