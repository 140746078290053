import Image from "@shared/UI/Image";
import ImageCert from "@assets/images/upup/cert.png";
import BackgroundSection3 from "@assets/images/upup/bg-section-3.jpg";

const Section1 = () => {
  return (
    <div
      dir="section3"
      className="bg-auto bg-no-repeat py-6"
      style={{ backgroundImage: "url(" + BackgroundSection3 + ")" }}
    >
      <div className="w-full text-center flex justify-end">
        <Image default={ImageCert} />
      </div>
    </div>
  );
};

export default Section1;
