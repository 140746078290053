//import ReactDOM from "react-dom";

export const getHiddenElementHeight = (parent, element) => {
  try {
    const tempId = "tmp-" + Math.floor(Math.random() * 99999);
    const clonedElement = element.cloneNode(true);
    clonedElement.setAttribute("id", tempId);
    clonedElement.style.display = "block";
    clonedElement.style.position = "absolute";
    clonedElement.style.height = "auto";
    clonedElement.style.width = "2000px";
    clonedElement.style.left = "0";
    parent.append(clonedElement);
    const appendedElement = document.getElementById("" + tempId);
    if (!!appendedElement) {
      const elementHeight = appendedElement.offsetHeight;
      console.log(appendedElement);
      appendedElement.remove();
      return elementHeight;
    }
  } catch (error) {
    console.log(error);
  }
  return 0;
};

export const webpSupport = () => {
  try {
    const canvas =
      typeof document === "object" ? document.createElement("canvas") : {};
    canvas.width = canvas.height = 1;
    return canvas.toDataURL
      ? canvas.toDataURL("image/webp").indexOf("image/webp") === 5
      : false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
