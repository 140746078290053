import { CSSTransition } from "react-transition-group";
import styles from "./styles.module.scss";

const Loading = (props) => {
  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      in={props.active}
      timeout={300}
      classNames={{
        enterActive: styles.leftSlideEnterActive,
        exitActive: styles.leftSlideExitActive,
      }}
    >
      <div className="fixed flex justify-center items-center w-full left-0 top-0 h-full bg-black bg-opacity-70 text-white text-4xl">
        <div className={styles.loader}></div>
      </div>
    </CSSTransition>
  );
};

export default Loading;
