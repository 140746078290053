const Label = (props) => {
  let jsx = {};
  switch (props.as) {
    case "label":
      jsx = (
        <label
          {...props}
          className={`${props.color || "text-white"} ${props.className}`}
        >
          {props.children}
        </label>
      );
      break;
    case "h1":
      jsx = (
        <h1 className={`${props.color || "text-white"} ${props.className}`}>
          {props.children}
        </h1>
      );
      break;
    case "h2":
      jsx = (
        <h2 className={`${props.color || "text-white"} ${props.className}`}>
          {props.children}
        </h2>
      );
      break;
    case "h3":
      jsx = (
        <h3 className={`${props.color || "text-white"} ${props.className}`}>
          {props.children}
        </h3>
      );
      break;
    case "p":
      jsx = (
        <p className={`${props.color || "text-white"} ${props.className}`}>
          {props.children}
        </p>
      );
      break;
    case "span":
      jsx = (
        <span className={`${props.color || "text-white"} ${props.className}`}>
          {props.children}
        </span>
      );
      break;
    default:
      jsx = (
        <div className={`${props.color || "text-white"} ${props.className}`}>
          {props.children}
        </div>
      );
  }
  return jsx;
};

export default Label;
