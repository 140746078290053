import styles from "./styles.module.scss";
const TextLists = (props) => {
  return (
    <>
      <ul
        className={`${styles.listStyle} ${styles[props.color] || "default"} ${
          styles[props.size] || "md"
        } ${props.className}`}
      >
        {props.children}
      </ul>
    </>
  );
};
export default TextLists;
