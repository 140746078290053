import { server as config } from "../server-config";
import { sendRequest } from "@utils/httpRequest";

export const createNewOrder = async (formData, token) => {
  return await sendRequest(
    `${config.apiHostname}/upup_order`,
    "POST",
    formData
  );
};
