import { Field } from "formik";
import Label from "@shared/UI/Label";

const PaymentChannelItems = (props) => {
  return (
    <>
      <Label
        htmlFor={props.id}
        as="label"
        color="text-black"
        className={`text-xl xl:text-3xl w-full sm:w-72 px-4 py-2 rounded-md border-2 border-gray-200 mr-4 cursor-pointer 
      hover:bg-red-800 hover:text-white ${
        props.activeValue === props.value && "bg-red-800 text-white"
      }`}
      >
        <Field
          type="radio"
          id={props.id}
          name="paymentType"
          value={props.value}
        />{" "}
        {props.children}
      </Label>
    </>
  );
};

export default PaymentChannelItems;
