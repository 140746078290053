import Image from "@shared/UI/Image";
import styles from "./styles.module.scss";
const FigureItems = (props) => {
  return (
    <figure className={`${styles.figture}`}>
      <Image default={props.image} />
    </figure>
  );
};
export default FigureItems;
