import FooterItems from "./FooterItems/FooterItem";
import imageTelIcon from "@assets/images/upup/phone-icon.png";
import imageLineIcon from "@assets/images/upup/line-icon.png";
import imageFbIcon from "@assets/images/upup/fb-icon.png";

const Footer = () => {
  return (
    <div className="w-full flex flex-row flex-wrap py-2 sm:py-4 xl:py-8">
      <FooterItems image={imageTelIcon}>
        <a href="tel:+6621131314" title="โทรเลย">
          02-113-1314
        </a>
      </FooterItems>
      <FooterItems image={imageFbIcon}>
        <a
          href="https://www.facebook.com/MediashoppingTH"
          rel="noreferrer"
          target="_blank"
          title="Facebook"
        >
          mediashopping
        </a>
      </FooterItems>
      <FooterItems image={imageLineIcon}>
        <a
          href="https://line.me/R/ti/p/@mediashopping"
          rel="noreferrer"
          target="_blank"
          title="Line"
        >
          @mediashopping
        </a>
      </FooterItems>
    </div>
  );
};

export default Footer;
