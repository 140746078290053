import { useState, useRef, useContext, useEffect, useCallback } from "react";
import Label from "@shared/UI/Label";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputForm from "./InputForm/InputForm";
import SelectTypeForm from "./SelectTypeForm/SelectTypeForm";
import PaymentChannel from "./PaymentChannel/PaymentChannel";
import Loading from "./Loading/Loading";
import { initialState } from "./initialInputState";
import { UpupContext } from "@shared/context/upupContext";
import * as OrderProvider from "@provider/OrderProvider";
import ReCAPTCHA from "react-google-recaptcha";

const Section7 = () => {
  const [data] = useState(initialState);
  const [imageFile, setImageFile] = useState(null);
  const [errorRecaptcha, setIsErrorRecaptcha] = useState(false);

  const recaptchaRef = useRef();
  const sectionPurchaseRef = useRef();
  const context = useContext(UpupContext);

  const scrollIntoView = useCallback(() => {
    sectionPurchaseRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [sectionPurchaseRef]);

  useEffect(() => {
    if (context.signalScrollToPurchase) {
      scrollIntoView();
      context.setSignalScroolPurchase(false);
    }
  }, [context, scrollIntoView]);

  const resetFormHandler = (resetForm) => {
    resetForm();
    setImageFile(null);
  };

  const validateRecaptcha = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) return true;
    return false;
  };

  const submitHandler = async (input, resetForm) => {
    if (!context.isSubmiting) {
      if (!validateRecaptcha()) {
        setIsErrorRecaptcha(true);
        return;
      }
      context.setSubmiting(true);
      setIsErrorRecaptcha(false);
      scrollIntoView();
      const formData = new FormData();
      formData.append("recapthca", recaptchaRef.current.getValue());
      formData.append("product", input.type);
      formData.append("fullname", input.fullname);
      formData.append("phone", input.phone);
      formData.append("address", input.address);
      formData.append("payment_type", input.paymentType);
      if (input.paymentType === "TRANSFER" && imageFile) {
        formData.append("files", imageFile);
      }

      try {
        const responseData = await OrderProvider.createNewOrder(formData);
        if (responseData.status === "SUCCESS") {
          context.setSubmitDone(true);
          resetFormHandler(resetForm);
        }
      } catch (error) {
        alert("ทำรายการไม่สำเร็จกรุณาติดต่อเจ้าหน้าที่ หรือลองใหม่อีกครั้ง");
        console.log(error.message);
      }
      context.setSubmiting(false);
    }
  };

  return (
    <div
      dir="section7"
      className="relative px-4 sm:px-10 md:px-20 py-4 bg-white "
      ref={sectionPurchaseRef}
    >
      <Loading active={context.isSubmiting} />
      {context.isSubmitDone ? (
        <div className="text-5xl md:text-7xl">
          <Label color="text-black" className="font-bold text-center my-5">
            <span className="text-green-400">สั่งซื้อสินค้าเรียบร้อยแล้ว</span>
            <div className="text-gray-600 text-3xl md:text-4xl">
              เจ้าหน้าที่จะดำเนินการตรวจสอบสินค้า และจะติดต่อกลับ
              เพื่อยืนยันการจัดส่ง <br />
            </div>
          </Label>
        </div>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={data}
          validationSchema={Yup.object({
            fullname: Yup.string()
              .min(5, "กรุณาระบุชื่อให้ถูกต้อง")
              .required("กรุณากรอก ชื่อ นามสกุล"),
            phone: Yup.string()
              .matches(/^\d+$/, "เฉพาะตัวเลขเท่านั้น")
              .min(9, "รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง")
              .max(10, "รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง")
              .required("กรุณากรอก เบอร์โทรศัพท์"),
            address: Yup.string().required("กรุณากรอก ที่อยู่ ที่ต้องจัดส่ง"),
            type: Yup.string().required("กรุณาเลือก สินค้าที่ต้องการสั่งซื้อ"),
            paymentType: Yup.string().required("กรุณาเลือก ช่องทางการชำระเงิน"),
          })}
          onSubmit={(values, { resetForm }) => {
            submitHandler(values, resetForm);
          }}
        >
          {(formikProps) => (
            <Form>
              <div className="pb-4">
                <Label
                  color="text-black"
                  className="text-3xl sm:text-4xl lg:text-5xl xl:text-7xl font-bold"
                >
                  เลือกจำนวนที่ต้องการ
                </Label>
              </div>
              <div
                role="group"
                aria-labelledby="my-radio-group"
                className="pl-0 sm:pl-6 border-b-4 border-gray-200 pb-4"
              >
                <SelectTypeForm id="radioType1" name="type" value="1">
                  UPUP อาหารเสริมผู้ชาย 1 กล่อง 299 บาท
                </SelectTypeForm>
                <SelectTypeForm id="radioType2" name="type" value="2">
                  <label className="text-red-600">*ขายดี*</label> UPUP
                  อาหารเสริมผู้ชาย 4 กล่อง 900 บาท
                </SelectTypeForm>
                <div className="text-red-600 text-3xl mt-4">
                  <ErrorMessage name="type" />
                </div>
              </div>

              <div className="py-4">
                <Label
                  color="text-black"
                  className="text-3xl sm:text-4xl lg:text-5xl xl:text-7xl font-bold"
                >
                  กรอกข้อมูลการจัดส่ง
                </Label>
              </div>
              <InputForm
                label="ชื่อ - นามสกุล"
                name="fullname"
                type="text"
                placeholder="โปรดระบุชื่อ - นามสกุล"
              />
              <InputForm
                label="เบอร์ติดต่อที่สะดวก"
                name="phone"
                type="text"
                placeholder="โปรดระบุเบอร์โทร"
              />
              <InputForm
                label="ที่อยู่จัดส่งสินค้า"
                name="address"
                as="textarea"
                placeholder="โปรดระบุที่อยู่"
                rows={5}
              />
              <PaymentChannel
                imageFile={imageFile}
                setImageFile={setImageFile}
                formikProps={formikProps}
              />
              <div className="mb-4">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LeEKtoaAAAAAE4-jIN4Rh05qsk9LfLswd3B2pfH"
                />
                {errorRecaptcha && (
                  <div className="text-red-600 text-2xl">
                    กรุณาคลิ๊กที่นี่ เพื่อยืนยันว่าคุณไม่ใช่โปรแกรมอัตโนมัติ
                  </div>
                )}
              </div>

              <button
                type="submit"
                className="text-2xl sm:text-3xl xl:text-4xl text-white bg-red-600 py-3 font-bold rounded-md w-full
              hover:bg-red-800"
              >
                ยืนยันคำสั่งซื้อ
              </button>
              <Label
                color="text-black"
                className="text-xl sm:text-2xl xl:text-3xl w-full text-center py-4 border-b-4 border-gray-200"
              >
                กรุณากดปุ่มยืนยันการสั่งซื้อ
                และรอเจ้าหน้าที่ติดต่อกลับเพื่อยืนยันการจัดส่ง
              </Label>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
export default Section7;
