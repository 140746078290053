import Label from "@shared/UI/Label";
import styles from "./styles.module.scss";
import CountdownComponent from "@components/UpupComponents/Section6/Countdown/Countdown";

const Section6 = () => {
  //const LimitTimeCountdown = new Date("2021-05-31");

  return (
    <div dir="section6" className="bg-red-800 py-4 sm:py-10">
      <div className="text-center my-2">
        <Label
          className={`text-2xl md:text-6xl lg:text-8xl font-bold ${styles.textShadow}`}
        >
          พิเศษ สั่งซื้่อวันนี้{"  "}
          <span className="text-relative-1/2 xl:text-relative-2 ml-4">
            จัดส่งฟรี!
          </span>
        </Label>
      </div>
      <div className="text-center">
        <div className="mx-auto inline-block bg-white rounded-md pt-4 px-10 pb-10">
          <div className="text-center">
            <CountdownComponent
              date={Date.now() + 1800000}
              className={`text-red-800 text-4xl md:text-8xl lg:text-9xl ${styles.textShadow}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section6;
